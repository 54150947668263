#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* ELEMENT OVERRIDES */
img {
  max-width: 100%;
}

/* UTILITY CLASSES */
.hidden {
  visibility: hidden;
}
